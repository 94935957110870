/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        $('.navbar-toggle').click(function(event) {
      $('.nav-slide').toggleClass('open');
      $('body').toggleClass('canvas-slid');
      $('.page-overlay').toggleClass('overlayed');
      $('.fa-times').toggleClass('close');
    });

    $('.page-overlay').click(function(event) {
      $('.fa-times').removeClass('close');
      $('.nav-slide').removeClass('open');
      $('body').removeClass('canvas-slid');
      $(this).removeClass('overlayed');
    });

    $('.fa-times').click(function(event) {
      $('.nav-slide').removeClass('open');
      $('body').removeClass('canvas-slid');
      $('.page-overlay').removeClass('overlayed');
      $(this).removeClass('close');
    });

    $('#menu-item-3325').click(function(event) {
      $('.searchbar').slideToggle('fast');
    });

    $(window).load(function() {
      $('.bxslider').bxSlider({
      mode: 'fade',
      captions: false,
      pager: true,
      controls: true,
      nextText: '<i class="fa fa-angle-right fa-5x"></i>',
      prevText: '<i class="fa fa-angle-left fa-5x"></i>',
      auto: true,
      pause: 5000,
      speed: 700,
      onSliderLoad: function(){
          $(".bxslider").css("visibility", "visible");

          
        },

      onSlideBefore: function ($slideElement, oldIndex, newIndex) {
          
          $('.active-slide').removeClass('active-slide');
          
      },

      onSlideAfter: function ($slideElement, oldIndex, newIndex) {
          $('.bxslider>li').eq(newIndex).addClass('active-slide');
      },
    
      });
    });

     $(window).load(function() {
      $('.bxslider-front').bxSlider({
      mode: 'fade',
      captions: false,
      pager: true,
      controls: true,
      nextText: '<i class="fa fa-angle-right fa-5x"></i>',
      prevText: '<i class="fa fa-angle-left fa-5x"></i>',
      auto: true,
      pause: 12000,
      speed: 700,
      onSliderLoad: function(){
          $(".bxslider-front").css("visibility", "visible");

          
        },

      onSlideBefore: function ($slideElement, oldIndex, newIndex) {
          
          $('.active-slide').removeClass('active-slide');
          
      },

      onSlideAfter: function ($slideElement, oldIndex, newIndex) {
          $('.bxslider-front>li').eq(newIndex).addClass('active-slide');
      },
    
      });
    });


    $('.dropdown-header').each(function(){
      var $this = $(this);
      // use .add() and .nextUntil() to get both the .section-header
      // and .section-item elements into a single set for our .wrapAll() call
      $this.add($this.nextUntil('.dropdown-header', '.menu-item'))
        .wrapAll('<div class="menu-column"></div>');
    });


        
            
       

       /* function initialize() {
          var myLatLng = new google.maps.LatLng(59.33928, 18.069768);
          var mapCanvas = document.getElementById('map');
          var mapOptions = {
            center: myLatLng,
            zoom: 13,
            mapTypeId: google.maps.MapTypeId.ROADMAP
          };
          var map = new google.maps.Map(mapCanvas, mapOptions);
          var marker = new google.maps.Marker({
              position: myLatLng,
              title:"MRG Match Recruitment ", 
              url: 'http://goo.gl/MMibDn'
          });

          // To add the marker to the map, call setMap();
          marker.setMap(map);
          google.maps.event.addListener(marker, 'click', function() {
            window.open(
              this.url,
              '_blank' // <- This is what makes it open in a new window.
            );
            
            console.log(this);
            
        });
        }
        google.maps.event.addDomListener(window, 'load', initialize); */

       /* $(document).ready(function() {

            $('#kontaktform').bootstrapValidator({
                //submitButtons: ".nej",
                feedbackIcons: {
                    valid: 'glyphicon glyphicon-ok',
                    invalid: 'glyphicon glyphicon-remove',
                    validating: 'glyphicon glyphicon-refresh'
                },
                fields: {
                    lastname: {
                        feedbackIcons: true,
                        validators: {
                            notEmpty: {
                                message: 'Skriv in ditt efternamn',
                            },
                        }
                    },

                    forename: {
                        feedbackIcons: true,
                        validators: {
                            notEmpty: {
                                message: 'Skriv in ditt förnamn',
                            },
                        }
                    },
                    personnr: {
                        feedbackIcons: true,
                        validators: {
                            notEmpty: {
                                message: 'Skriv in ditt personnummer',
                            },
                        }
                    },
                    email: {
                        feedbackIcons: true,
                        validators: {
                            notEmpty: {
                                message: 'Skriv in din E-post',
                            },
                            emailAddress: {
                                message: 'Fyll i en korrekt E-post adress'
                            }
                        }
                    },
                 
                  }
            });

               
        });*/
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        
      
      
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
